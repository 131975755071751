export async function createCommit(state, treeCreated, changes) {
    const { octokit, repo, fork, latestCommitSha } = state;
    const message = treeCreated
        ? changes.commit
        : typeof changes.emptyCommit === "string"
            ? changes.emptyCommit
            : changes.commit;
    // https://developer.github.com/v3/git/commits/#create-a-commit
    const { data: latestCommit } = await octokit.request("POST /repos/{owner}/{repo}/git/commits", {
        owner: fork,
        repo,
        message,
        tree: state.latestCommitTreeSha,
        parents: [latestCommitSha],
    });
    return latestCommit.sha;
}
